* {
  padding: 0;
  margin: 0;
  box-sizing: border-box !important;
}

html,
body {
  box-sizing: border-box !important;
}

li {
  list-style: none;
}
